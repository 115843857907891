import React from 'react';
import Box from '@material-ui/core/Box';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';

import theme from '../../constants/theme/theme';

const useStyles = makeStyles({
    photo: {
        position: 'absolute',
        right: '0',
        top: '0',
        height: '102%',
        display: 'flex',
        alignItems: 'flex-start',
        overflow: 'visible',
        width: '50%',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.primary.main,
            height: '75vw',
            maxHeight: '500px',
            marginLeft: '-64px',
            marginRight: '64px',
            marginTop: '48px',
            overflow: 'hidden',
            position: 'static',
            justifyContent: 'center',
            width: 'calc(100% + 128px)',
        },
    },
    image: {
        height: 'auto',
        transition: 'transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), width 0.6s cubic-bezier(0.25, 1, 0.5, 1), height 0.6s cubic-bezier(0.25, 1, 0.5, 1), margin-right 0.6s cubic-bezier(0.25, 1, 0.5, 1)',
        width: '102%',
        [theme.breakpoints.down('md')]: {
            opacity: '0.5',
            width: '120%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '600px',
            marginLeft: '-16px',
            opacity: '1',
            width: '100%',
        },
    }
});

const HomeIntroPhoto = ({ width }) => {
    const classes = useStyles();
    const imageRef = React.useRef();

    const photoData = useStaticQuery(graphql`
        query {
            desktopPhoto: file(relativePath: { eq: "app-shots/app-shots.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                        quality: 50
                        width: 576
                    )
                }
            }
            mobilePhoto: file(relativePath: { eq: "app-shots/app-shots-dark.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                        quality: 50
                        width: 800
                    )
                }
            }
        }
    `);

    const desktopPhoto = getImage(photoData.desktopPhoto);
    const mobilePhoto = getImage(photoData.mobilePhoto);

    const handleScrollEffect = () => {
        // Increment of up to max scale of 1.5
        const scale = window.pageYOffset > 600 ? 1.1 : 1 + ((window.pageYOffset / 600) * 0.1);
        if (imageRef.current) {
            imageRef.current.style.transform = `scale(${scale})`;
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScrollEffect);
        return () => window.removeEventListener('scroll', handleScrollEffect);
    }, []);

    return (
        <Box className={classes.photo} ref={imageRef}>
            <GatsbyImage
                alt="SpeedyDock app showing dry stack marina and boat club features on a mobile device"
                image={
                    isWidthUp('md', width) ?
                    desktopPhoto :
                    mobilePhoto
                }
                className={classes.image}
            />
        </Box>
    );
};

export default withWidth()(HomeIntroPhoto);
