import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Schedule from '@material-ui/icons/Schedule';
import Devices from '@material-ui/icons/Devices';
import Speed from '@material-ui/icons/Speed';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import SyncAltOutlined from '@material-ui/icons/SyncAltOutlined';

const StrengthPoints = () => (
    <Container align="center">
        <Grid container spacing={8}>
            <Grid item sm={6} md={4}>
                <Box color="primary.light" pb={2}>
                    <Speed fontSize="large" color="inherit" />
                </Box>
                <Typography variant="h5" component="h3" color="secondary">
                    Speed
                </Typography>
                <Box pt={3}>
                    <Typography color="secondary">
                        Speed is in our name. We've worked hard to make sure our app is built with speed in mind so you can continue operations with ease. SpeedyDock is built to provide you with a pleasant experience.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Box color="primary.light" pb={2}>
                    <Schedule fontSize="large" color="inherit" />
                </Box>
                <Typography variant="h5" component="h3" color="secondary">
                    Reliability
                </Typography>
                <Box pt={3}>
                    <Typography color="secondary">
                        We know you count on us to do your business. We work hard to ensure our app is available for you when you need it most. We go the extra mile of not releasing changes during your business hours.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Box color="primary.light" pb={2}>
                    <Devices fontSize="large" color="inherit" />
                </Box>
                <Typography variant="h5" component="h3" color="secondary">
                    Cross-Platform
                </Typography>
                <Box pt={3}>
                    <Typography color="secondary">
                        SpeedyDock supports a wide range of devices, making it convenient for marinas and their customers to access from anywhere. Support is available for Android, iOS, and desktop devices.
                    </Typography>
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid item xs={12} sm={2}>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={4}>
                <Box color="primary.light" pb={2}>
                    <SyncAltOutlined fontSize="large" color="inherit" />
                </Box>
                <Typography variant="h5" component="h3" color="secondary">
                    True Real-Time
                </Typography>
                <Box pt={3}>
                    <Typography color="secondary">
                        SpeedyDock is a push based real-time system. When multiple employees and customers are using the system, there is no delay in receiving updates. This is extremely important and beneficial for larger operations.
                    </Typography>
                </Box>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={3}>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={4}>
                <Box color="primary.light" pb={2}>
                    <BuildOutlined fontSize="large" color="inherit" />
                </Box>
                <Typography variant="h5" component="h3" color="secondary">
                    Highly Customizable
                </Typography>
                <Box pt={3}>
                    <Typography color="secondary">
                        At SpeedyDock, we know each marina and dealer may function a bit differently. Every SpeedyDock product is highly customizable and can be tailored to meet the needs of your business.
                    </Typography>
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid item xs={12} sm={2}>
                </Grid>
            </Hidden>
        </Grid>
    </Container>
);

export default StrengthPoints;
