import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';

import FadeIn from '../transitions/FadeIn';
import theme from '../../constants/theme/theme';

const benefits = [
    {
        title: 'Efficiency',
        description: 'Improve efficiency of your office staff, forklift drivers, and dockhands, resulting in reduced labor demand.',
    },
    {
        title: 'Prevent overbooking',
        description: 'Use our launch and boat reservation scheduler to easily manage your requests and capacity daily.',
    },
    {
        title: 'Improved communication',
        description: 'Reduce phone calls from customers and reduce errors in communication between the main office, drivers, and dockhands.',
    },
    {
        title: 'Accountability',
        description: 'Easily see what each driver and dockhand is doing based on launches scheduled for that day. Access a full history of which employee or customer requested, touched, or moved a boat at any time.',
    },
    {
        title: 'Analytics',
        description: 'We will automatically show you launch and boat reservation statistics based upon analytics through data collected by SpeedyDock.',
    },
];

const useStyles = makeStyles({
    description: {
        color: '#ccc',
    },
    image: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
            margin: '0 auto',
            position: 'relative',
            right: '-20px',
        },
    }
});

const OperationalBenefits = () => {
    const classes = useStyles();
    const photoData = useStaticQuery(graphql`
        query {
            operationalBenefitsPhoto: file(relativePath: { eq: "benefits/operational-benefits.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED
                        quality: 50
                        width: 399
                    )
                }
            }
        }
    `);

    const photo = getImage(photoData.operationalBenefitsPhoto);

    return (
        <Container>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={5}>
                    <GatsbyImage
                        alt="Boat club and dry stack marina app for marina forklift drivers, office staff, and dockhands"
                        className={classes.image}
                        image={photo}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Box pb={5} display="flex" alignItems="center" style={{ height: '100%' }}>
                        <Box>
                            {
                                benefits.map(benefit => (
                                    <FadeIn key={benefit.title}>
                                        <Box pb={6}>
                                            <Typography variant="h5" component="h2" color="secondary">
                                                <strong>{benefit.title}</strong>
                                            </Typography>
                                            <Typography className={classes.description}>
                                                {benefit.description}
                                            </Typography>
                                        </Box>
                                    </FadeIn>
                                ))
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OperationalBenefits;
