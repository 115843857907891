import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';

import FadeIn from '../transitions/FadeIn';
import theme from '../../constants/theme/theme';

const useStyles = makeStyles({
    image: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
            margin: '-24px auto 0',
            position: 'relative',
            right: '-20px',
        },
    }
});

const benefits = [
    {
        title: 'Convenience',
        description: 'Scheduling can happen any time of day - even after the marina office has closed.',
    },
    {
        title: 'Better experience',
        description: 'Scheduling could not be simpler -- no more text message strings, leaving a voicemail, or waiting on a busy phone line.',
    },
    {
        title: 'Ease of use',
        description: 'Customers can modify or check the status of their launch or boat reservation from any device with the SpeedyDock app or a web browser.',
    },
    {
        title: 'Peace of mind',
        description: 'Real-time notifications are provided when their launch is complete or their boat is returned to storage. Club customers receive notifications when their reservation time is approaching and as their reservation end time is coming up.',
    },
    {
        title: 'Realistic expectations',
        description: 'Wait times are realistic because launches are booked according to the marina schedule.',
    }
];

const CustomerBenefits = () => {
    const classes = useStyles();
    const photoData = useStaticQuery(graphql`
        query {
            customerBenefitsPhoto: file(relativePath: { eq: "benefits/customer-benefits.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED
                        quality: 50
                        width: 399
                    )
                }
            }
        }
    `);

    const photo = getImage(photoData.customerBenefitsPhoto);

    return (
        <Container>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={7}>
                    <Box pb={5} display="flex" alignItems="center" style={{ height: '100%' }}>
                        <Box>
                            {
                                benefits.map(benefit => (
                                    <FadeIn key={benefit.title}>
                                    <Box pb={6}>
                                        <Typography variant="h5" component="h2">
                                            <strong>{benefit.title}</strong>
                                        </Typography>
                                        <Typography>
                                            {benefit.description}
                                        </Typography>
                                    </Box>
                                </FadeIn>
                            ))
                        }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <GatsbyImage
                        alt="Boat club and dry stack marina customer app"
                        className={classes.image}
                        image={photo}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default CustomerBenefits;
